.appointmentCustomer .form-group:last-child {
    margin-bottom: 6px !important;
}

.appointment-comment {
    box-shadow: none;
    border-left: 0;
    border-right: 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    margin: 10px 0;
}

.appointment-readonly .appointment-comment {
    margin: 0 0 0 -10px;
    border-top: 0;
    border-bottom: 0;
}

.appointment-comment td {
    box-shadow: none !important
}

.appointmentSearch h3 {
    margin-top: 15px;
    font-size: 15px;
}

.appointmentSearch .close {
    position: absolute;
    top: 10px;
    right: 170px;
    z-index: 999;
}

