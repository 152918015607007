
.skynet-nav-tabs {
    height: 5.4rem !important;
    background-color: transparent !important;
    border-bottom: .1rem solid var(--neutral-30) !important;
    border-top: .1rem solid var(--module-container-divider);
    white-space: nowrap;

    .nav-item {
        height: 5.3rem !important;

        a {
            top: 0.7rem !important;
            height: 5.3rem !important;
        }

        a:before {
            top: 4.2rem !important;
        }
    }



    .nav-item a:focus:not(:active):after {
        border: none !important;
    }

    .dropdown-toggle {
        top: 0.7rem !important;
    }

    .dropdown-menu{
        top: 5.1rem !important;    
    }
}

.card > .tab-content {
    background-color: var(--module-container-bg-color) !important;
}