.btn-outline {
    background-color: transparent;
    color: inherit;
    transition: all .5s;
}

.btn-wrap-text {
    overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}