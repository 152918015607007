.additionalUsers .inline {
    display: flex;
    flex-direction: row;
}

.additionalUsers input {
    width: 55px !important;
}

.additionalUsers button {
    min-width: 95px;
    margin-left: 10px;
}

.vonAddon {
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #dcdcdc;
}

.priceExplanation {
    margin-bottom: 20px;
}

.priceExplanation h4 {
    font-weight: 600;
    margin-bottom: 0px;
}

.priceExplanation div + h4 {
    margin-top: 15px;
    font-weight: 600;
}

.priceExplanation .row {
    padding-top: 3px;
    padding-bottom: 3px;
}

.orderDetails .form-horizontal {
    margin-bottom: 0
}

.orderDetails .table {
    margin-bottom: 5px;
}

@media screen and (max-width: 991px) {
    .orderDetails button.copy {
        margin: 8px 0 0 !important
    }
}