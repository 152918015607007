.searchWrapper {
    text-align: center;
    margin-top: 100px;
}

.searchWrapper h1 {
    font-size: 18px;
}

.searchWrapper .searchInput {
    width: 500px;
    margin: auto;
}

.searchInput .loader {
    position: absolute;
    right: -80px;
    top: -22px;
}