@import '~@vismaux/vud/dist/css/vud.light-dark.mode.min.css';

:root {
  --custom-navigation-default-bg: linear-gradient(to right, #48237D, #8049CC);
  --custom-anchor-focus-outline: #8049CC;
  --custom-vertical-nav-default-item-hover-bg: #EEE7F9;
  --custom-navigation-secondary-item-focus-border-color: #8049CC;
  --custom-button-hover-border-color: #8049CC;
  --custom-module-container-hover-bg-color: #EEE7F9;
  --custom-dropdown-menu-hover-bg: #EEE7F9;
  --custom-button-hover-bg: #EEE7F9;
  --custom-table-hover-border-color: #8049CC;
  --custom-table-hover-bg: #EEE7F9;
  --custom-input-hover-border-color: #8049CC;
  --vertical-nav-default-collapse-btn-bg: #8049CC !important;
  --vertical-nav-default-collapse-btn-hover-bg: #8049CC !important;
  --icon-bg:#8049CC !important;
  --custom-input-focus-border-color: #8049CC;
  --custom-badge-info-bg: #8049CC;
  --custom-root-background-color: #F5F4F0;
  --custom-dark-anchor-color: #6cb2e1;
}

body>iframe {
  display: none;
}


body {
  padding-top: 88px;
}

.vertical-nav {
  z-index: 11;
}

.max-width-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.cursorpointer {
  cursor: pointer;
}

.cursordefault {
  cursor: default !important;
}

.btn.left:focus {
  background-position: left top !important;
}

/* This is so buttons in table cells will be vertical aligned in middle */
td .btn {
  margin: 0;
}

.btn.left:focus span {
  background-position: right top !important;
}

.legend {
  font-weight: normal;
  margin-bottom: 17px;
  color: #333;
}

.btn-toolbar .btn-group .btn {
  min-width: 160px !important;
  z-index: 0;
}

.btn-toolbar>.btn-group:first-child {
  margin-left: 0;
}

.mainContainer {
  min-height: 500px;
  margin-top: 40px;
}

footer img {
  margin-bottom: 3px;
}

input[readonly],
.form-control[readonly] {
  padding-left: 0;
  border-radius: 5px;
}

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-style: normal !important;
}

.infotypeContainer .infolabel {
  margin: 0 4px 7px 0;
}

.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group {
  z-index: 1;
}

.align-customerIcon {
  vertical-align: middle;
  top: -5px;
  padding-left: 5px;
}

.skynet-pr-20 {
  padding-right: 20px;
}

.align-saveIcon {
  padding-left: 10px;
}

@media screen and (min-width: 1450px) {
  .appointmentActions .btn-special-action {
    margin-left: 61px !important;
  }
}

@media screen and (max-width: 1299px) {
  .btn-toolbar>.btn-group {
    margin-top: 5px;
  }
}

.checkbox {
  margin: 0;
}

.loginForm {
  width: 160px;
  margin: 0 auto;
}

.loginForm h2 {
  border-bottom: 0;
  font-family: "Open sans", sans-serif;
  margin-bottom: 5px;
  text-align: center;
}

.modal {
  display: block;
}

.ReactModalPortal {
  z-index: 9990;
  position: relative;
}

.ReactModal__Body--open {
  overflow: hidden;
}


.customer-number-copy {
  display: inline-block;
  margin-left: 10px;
}

.customer-number-copy:hover {
  opacity: 0.7;
}

.additionalUsers .vismaicon-remove-circle {
  padding-right: 20px;
}

.radio-inline input[type="radio"],
.checkbox-inline input[type="checkbox"] {
  margin-top: 0;
  margin-left: -16px;
}

.radio-inline+.radio-inline,
.checkbox-inline+.checkbox-inline {
  padding-left: 20px;
}

.inline {
  display: inline;
}

.word-break {
  word-break: break-word;
}

.no-wrap{
  white-space: nowrap;
}

.table.table-hover-custom>tbody>tr:hover>td {
  cursor: default !important;
}

.rbt-aux .rbt-close {
  margin-top: -6px !important;
}

.form-group .rbt-menu, .form-control .rbt-menu {
  top: 30px !important;
}

#headerCustomerSearch {
  top: 0px !important;
  transform: translate3d(0px, 31px, 0px) !important;
  width: 100%;
}

.sortable {
  cursor: pointer;
}

/* This doesn't exist in VUD so we have our own css for it */
.btn-warning {
  color: var(--alert-warning-text);
  border-color: var(--alert-warning-border);
  background-color: var(--alert-warning-bg);
}

.form-horizontal .control-label,
.form-horizontal label {
  white-space: pre-wrap;
}


#contentPushRight-oneLvl.col-md-12,
#contentPushRight-multiLvl.col-md-12 {
  width: 100%;
  display: contents;
}

.main-top {
  position: fixed;
  top: 42px;
  left: 0;
  z-index: 10 !important;
}

/*.panel>.panel-heading>.panel-customer {*/
/*  display: inline-block;*/
/*  border-bottom: none;*/
/*}*/

/*.panel>.panel-heading>.panel-customer>h3 {*/
/*  color: #000;*/
/*}*/

/*.panel>.panel-heading .panel-title-customer {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  flex-direction: column;*/
/*  width: 60%;*/
/*  color: #000000;*/
/*}*/

.panel-default>.panel-customer {
  width: 100%;
}

/*.panel-group>.panel>.panel-heading>.panel-title {*/
/*  position: relative;*/
/*  color: #000000 !important;*/
/*  font-size: 16px;*/
/*}*/

/*.panel>.panel-heading>.panel-sub-title {*/
/*  position: relative;*/
/*  color: #000000 !important;*/
/*  font-size: 14px;*/
/*  margin-top: 10px;*/
/*  padding-right: 20px;*/
/*  font-weight: bolder;*/
/*}*/

/*.panel>.panel-heading>.panel-sub-title>.panel-sub-between {*/
/*  padding-right: 30px;*/
/*}*/


.customer-page-search {
  flex-grow: 1;
  width: 40%;
}

.horizontal-container {
  display: inline-block;
  width: 50%;
  padding-right: 20px;
  padding-left: 20px;
}


.horizontal-text-label-container {}

.horizontal-text-container {}


.horizontal-text-label {
  font-weight: bold;
}

.horizontal-texts-container> :last-child> :last-child {
  margin: 0;
}

.side-nav-links {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.side-nav-links>li {
  position: relative;
  display: block;
}

.side-nav-links>li>a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.side-nav-links>li>a:hover,
.side-nav-links>li>a:focus {
  text-decoration: none;
  background-color: #ffffff;
}

.side-nav-links>a:hover {
  background-color: #ffffff;
  border-color: #337ab7;
}

.panel>.panel-customer {
  border-bottom: none;
}

.panel-body-content {
  margin-top: 110px;
  width: 100%;
  border: none;
  border-radius: 5px;
}

/*.panel-primary>.panel-body {*/
/*  border-radius: 5px;*/
/*  border: solid 1px #e7e7e7;*/
/*  box-shadow: none;*/
/*  -webkit-box-shadow: none;*/
/*  -moz-box-shadow: none;*/
/*}*/

.panel-search {
  width: 100%;
  display: flex;
}

.panel-content {
  display: flex;
  padding-top: 5px;
}


.customer-page-search .rbt-input-hint-container {
  display: flex;
}

.customer-page-search .col-xs-4 {
  width: auto;
}

.sidenav {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  margin-bottom: 160px !important;
  background: #ffffff !important;
  border: none;
}

/* width */
::-webkit-scrollbar {
  width: 13px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b9c5ce;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #778e9e;
}

.search-label-container {
  padding-top: 3px;
}

.search-label {
  width: 150px;
}

.search-group>input {
  border-radius: 24px !important;
}

@media (max-width: 992px) {
  body {
    padding: 0;
  }
}

.table-col-width {
  display: flex;
  max-width: 200px;
}

.table-col-icon>a {
  text-align: right !important;
}

.list-group {
  border: none;
  box-shadow: none;
}

.list-group>.list-group-item,
.list-group>.list-group-item.active,
.list-group>.list-group-item:focus {
  z-index: 2 !important;
  margin: 0;
  padding: 7px 0;
}

.language {
  display: block;
  float: left;
  background: #ffffff;
  width: 110px;
  height: auto;
  position: relative;
  right: 100px;
}

.language>.languagedropdown {
  display: inline-block;
  border: none !important;
  background: transparent;
  list-style-type: none;
  padding: 0;
}

.language>.languagedropdown>li {
  display: inline-block;
  text-decoration: none;
  background: #ffffff !important;
  border: none !important;
  color: #000000 !important;
  box-shadow: none;
  padding: 5px;
  border-radius: 0 !important;
}

.language>.languagedropdown>li>a {
  display: inline-block;
  text-decoration: none;
  color: #000000;
  margin: 10px;
}

.languagedropdown>li>a:hover {
  background: #ccebff;
}

.languagedropdown>.btn-group>.btn:focus {
  outline-offset: 0;
}

.languagedropdown .col-xs-8 {
  float: none;
  width: auto;
  margin: 0;
}

.languagedropdown .btn-group>.btn:only-of-type {
  min-width: 0;
}

.languagedropdown .btn-group>.dropdown-menu {
  border: 0;
}

/* This is used when searching for products etc */
.rbt-menu {
  margin-top: -3rem !important;
  z-index: 100;
}

.button-list {
  list-style-type: none;
}

.shortcuts-container>.btn-group>ul {
  padding-inline-start: 10px;
}

.button-list>button {
  margin: 10px 10px 0 10px;
}

.button-list>button>li>a {
  color: #ffffff !important;
  text-decoration: none;
}

.form-group-icon {
  position: relative;
}

.form-group-icon>input {
  padding-left: 32px;
}

.form-group-icon>i {
  position: absolute;
  color: rgba(0, 0, 0, 0.54);
  left: 10px;
  top: 7px;
  bottom: calc(0.375rem - 1px);
  z-index: 10;
}

.form-control-placeholdericon {
  font-family: "Open sans", "Material Icons";
}
/*
.skynet-form .form-group div:not(.checkbox):not(.radio):not(.ql-container), .skynet-form .formgroup, .skynet-form input[readonly], .skynet-form .form-control[readonly]{
  padding-left: 40px !important;
}
*/
@media (max-width: 992px) {
  .sidenav {
    margin-bottom: 0;
  }
}

.item-container {
  width: 100%;
}

.item-name {
  display: inline-block;
  line-height: 30px;
  margin: 0px;
}

.item-button {
  float: right;
}

.add-button {
  float: right;
}

.item-container>p {
  margin: 0;
}

.shortcut-component-container>div.btn-group {
  float: right;
  margin-top: 14px;
}

.dropdown-menu .divider {
  display: block;
}

.btn-blue {
  background: red;
}

span.grippy {
  content: "....";
  width: 20px;
  display: inline-block;
  line-height: 5px;
  padding: 3px 4px;
  vertical-align: middle;
  margin-top: -0.8em;
  margin-right: 0.3em;
  font-size: 12px;
  font-family: sans-serif;
  letter-spacing: 3px;
  color: #cccccc;
  font-weight: bolder;
}

span.grippy::after {
  content: ".. .. .. ..";
}

.ShortcutListHeader {
  font-weight: bold;
}

.product-status {
  width: auto;
  padding-right: 0;
}

.customerFunctions {
  overflow: auto;
}

.product-text {
  padding-left: 0;
}

.order-body {
  overflow-y: auto;
}

.use-contact-button-container {
  width: 100%;
  padding: 0;
  margin: 0;
}

.use-contact-button {
  float: right;
}

.emailtemplate-approve-button-grp {
  width: 100%;
  margin-top: 20px;
}

.emailtemplate-btn {
  margin-bottom: 0;
  margin-right: 10px;
}

.emailtemplate-warningtext {
  display: inline-block;
  vertical-align: sub;
}

.btn-approval {
  width: auto;
}

.additional-vonlicenses-dropdown {
  padding: 0;
  display: none;
}

.open>.additional-vonlicenses-dropdown {
  display: inline-table;
  margin-top: 10px !important;
  z-index: 999 !important;
  position: absolute;
}

.additional-vonlicenses-dropdown-container {}

.additional-vonlicenses-dropdown-container p {
  line-height: inherit;
  display: inline;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.additional-vonlicenses-text {
  line-height: 0;
  height: 0;
}

.additional-vonlicenses-table-container {
  position: absolute;
  width: 500px;
}

.no-user-container {
  padding: 10px;
}

.dropdown-wrapper {
  position: relative;
}

.table-content-hover {
  display: block;
  position: absolute;
  max-width: 300px;
  background: white;
  z-index: 2;
  padding: 10px;
  word-break: break-word;
  border: 1px solid #7070709c;
  border-radius: 5px;
  margin-bottom: 5px;
}

.table-dropdown {
  display: block;
  position: absolute;
  width: auto;
  background: white;
  z-index: 2;
  padding: 10px;
  border: 1px solid #7070709c;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-left: 20px;
  color: #000;
}

.salesperson-table-dropdown {
  display: block;
  position: absolute;
  background: white;
  z-index: 2;
  padding: 10px;
  border: 1px solid #7070709c;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-left: 20px;
  color: #000;
}

.list-hover {
  display: block;
  position: absolute;
  min-width: 220px;
  background: white;
  z-index: 100;
  padding: 5px;
  word-break: break-word;
  border: 1px solid #7070709c;
  border-radius: 5px;
  margin-bottom: 5px;
  right: 20px;
}

.list-hover.list-group>.list-group-item,
.list-group>.list-group-item.active,
.list-group>.list-group-item:focus {
  min-height: unset;
}

.has-duplicate-button {
  margin-right: 5px;
  cursor: none;
  pointer-events: none;
}

.is-duplicate-checkmark {
  pointer-events: none;
}

.user-role-container {
  display: flex;
}

.user-role-description {
  width: auto;
  margin-bottom: 10px !important;
  font-style: italic;
}

.downloadable-template-description {
  width: 100%;
  margin-bottom: 10px !important;
  font-style: italic;
}




.editBudgetLayout {
  display: flex;
  justify-content: center;
}

.partner-level-btn {
  cursor: none;
  pointer-events: none;
  margin-right: 5px;
}

.ql-snow.ql-toolbar {
  border-radius: 5px 5px 0 0;
}

.ql-container.ql-snow {
  border-radius: 0 0 5px 5px;
}

.nav-pills>li>a {
  background: none;
}

.nav-pills>li>a:hover {
  background: none #dceefa;
}

.delete-discount-btn {
  margin-bottom: 0;
  min-width: max-content;
}

textarea.form-control {
  border-radius: 5px;
}

.infotypeheader {
  margin-bottom: 20px;
  margin-top: 20px;
}

.budgetFormContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
}

.budgetSelector {
  margin-bottom: 1rem;
  margin-right: 1rem;
  padding: 0.4rem;
  border: 1px solid #c8c8c8;
}

.yearBtn {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

.yearBtn:hover {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

.yearBtn:active {
  outline: none;
  border: none;
}

.yearBtn:focus {
  outline: 0;
}

.changeYearContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.yearText {
  font-size: 3rem;
  font-weight: 600;
  margin: 0 2rem 0 2rem;
}

.myStatsGraphContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 63rem;
  border: 1px solid rgb(217, 220, 222);
  border-radius: 2px;
  margin-bottom: 2rem;
}

.graph-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 2rem;
}

.graphComponentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35rem;
  padding: 1rem;
}

.graphController {
  display: flex;
  padding: 1rem;
  border-top: 1px solid #ddd;
}

.controllerContainer {
  display: flex;
  column-gap: 1rem;
}

.graphTitleContainer {
  color: #0974b3;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
}

.eekonomi-hover {
  right: unset !important;
  bottom: -35px;
}

.eekonomi-hover-text {
  color: black;
  display: inline;
}

.pong-modal {
  width: 1000px;
}

.pong-canvas {
  width: 100%;
}

.address-button-container {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 10px;
  float: right;
}

.address-button {
  float: right;
  margin-right: 8px;
  display: inline-flex;
  pointer-events: none;
}

.address-button-container> :first-child {
  margin-right: 0;
}

.address-remove {
  margin-left: 10px;
  pointer-events: initial;
  font-weight: bold;
}

.form-search {
  margin-bottom: 6px !important;
}

.education-warning-icon {
  float: left;
  margin-right: 15px;
}

.education-name {
  display: flex;
}

.subcustomer-search {
  margin-bottom: 10px;
}

.maincustomer-search-container {
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {

  .maincustomer-search-container {
    width: 50%;
  }
}

.blikk-checkbox-container {
  width: auto;
}

.blikk-checkbox-label {
  float: right;
}

.productoverviewpage {
  display: flex;
  justify-content: center;
  height: 84.5vh;
  gap: 2rem;
}

.productoverviewsection {
  height: 100%;
  margin-right: 0;
  padding: 0;
}

.productOverviewListContainer {
  height: 100%;
  padding-bottom: 9rem;
}

.productOverviewList {
  display: grid;
  grid-template-columns: repeat(auto-fill, 36rem);
  list-style-type: none;
  justify-content: space-between;
  grid-gap: 1rem;
  height: 100%;
  overflow-y: auto;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
}

.panel-controls {
  display: flex;
  flex-direction: column;
}

.productOverviewList::-webkit-scrollbar-track {
  visibility: hidden;
}

.imgBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProductOverviewItemImage {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #337ab7;
}

.ProductOverviewItemTitle {
  color: #337ab7;
  text-decoration: underline;
  cursor: pointer;
}

.ProductOverviewItemTitle:hover {
  color: #0a679c;
}

.activeProduct {
  border: 1px solid #337ab7;
}

.overflow-table-container {
  overflow-y: auto;
  height: 100%;
}

.overflow-table-container::-webkit-scrollbar {
  display: block;
  width: 1.5rem;
}

.overflow-table-container::-webkit-scrollbar-track {
  background-color: transparent
}

.overflow-table-body thead th {
  position: sticky;
  top: 0;
}

.overflow-table-body {
  border-collapse: collapse;
  width: 100%;
}

.editVersionContainer {
  height: 100%;
}

.editVersionNoPadding {
  padding: 0;
}

.informativeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  gap: 2rem;
}

.POforms-container {
  display: flex;
  flex-direction: column;
}

.dropdownContainer {
  display: flex;
  align-items: flex-end;
  margin-bottom: 4rem;
}

.dropdownContainer div {
  width: 100%;
}

.dropdownContainer p {
  margin: 0;
}

.titleAndLink {
  display: flex;
  justify-content: space-between;
}

.titleAndLink p {
  margin: 0;
}

.form-vertical {
  display: flex;
  flex-direction: column;
}

.form-vertical .form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.templateContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.templateinputcontainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.templateinputcontainer label {
  padding: 0;
}

.templateinputcontainer div {
  padding: 0;
  width: 100%;
}

.textinputscontainer .form-group {
  width: 100%;
  margin-bottom: 1.5rem;
}

.product-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.iconzindex {
  z-index: 20;
}

.addversionformcontainer {
  display: flex;
  flex-direction: column;
}

.addversionform {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.addversionform .form-group {
  display: flex;
  flex-direction: column;
}

.editableVersionList {
  height: 100%;
}

.alert-infoContainer {
  padding: 2rem;
  padding-left: 5rem;
}

.panelContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.table-data-deleteable {
  margin: auto;
  margin-right: 0;
  cursor: pointer;
}

.dropzonePreview {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.additionalInfo {
  width: 100%;
  padding: 3rem;
  background-color: #fff;
  position: relative;
  z-index: 9999;
}

.modalCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.informationModal {
  display: flex;
  left: 53rem;
  top: 10rem;
}

.smallModal {
  width: 60rem;
}

.bigModal {
  width: 120rem;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.productTextArea {
  height: 20rem;
  margin-bottom: 1rem;
  resize: none;
  border-radius: 6px;
}

.informationTextArea {
  height: 60rem;
}

.modalBtns {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 2rem;
}

@media (max-width: 1820px) {
  .panelContainer {
    flex-wrap: wrap;
    gap: 1rem;
  }
}

@media (max-width: 1950px) {
  .productOverviewList {
    grid-template-columns: repeat(auto-fill, 34rem);
  }

  .informationModal {
    left: 0;
    justify-content: center;
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

.filters> :not(:last-child) {
  margin-right: 5px;
}

.orderapproval-text-container {
  display: inline-block;
  width: 100%;
}

.orderapproval-form {
  margin-bottom: 0;
}

.budget-dropdown {
  padding-left: 0;
  margin-bottom: 20px;
}

.large-dropdown-width {
  width: 35rem;
  top: unset !important;
  position: absolute !important;
  padding: unset !important;
  z-index: 9999 !important;
}

.nonCustomerModalClass {
  width: fit-content;
}

.nonCustomerModalClass .quill {
  width: 100rem;
}

.nonCustomerModalClass .flexclass {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1455px) {
  .topFix {
    top: 92px;
  }

  .leftFix {
    top: 190px;
  }

  .shortcutFix {
    top: 128px;
  }

  .mainContainerFix {
    margin-top: 90px;
  }
}

.victorybar {
  height: 500px;
}

.create-appointment-checkbox-container {
  margin-bottom: 20px;
  display: flex;
}

.modal-body {
  height: unset !important;
  overflow-y: unset !important;
}

.user-image img {
  height: 52px;
  width: 52px;
}

.vismaicon.vismaicon-priority-high:before {
  background-image: url(../Images/24_prio_red.svg);
  -webkit-mask-image: url(../Images/24_prio_red.svg);
}

.vismaicon.vismaicon-priority-medium:before {
  background-image: url(../Images/24_prio_orange.svg);
  -webkit-mask-image: url(../Images/24_prio_orange.svg);
}

.vismaicon.vismaicon-priority-low:before {
  background-image: url(../Images/24_prio_green.svg);
  -webkit-mask-image: url(../Images/24_prio_green.svg);
}

.dark-theme .VictoryContainer tspan {
  fill: var(--body-font-color) !important;
}

.dark-theme .VictoryContainer path {
  stroke: var(--body-font-color) !important;
}

@media (prefers-color-scheme: dark) {
  .VictoryContainer path {
    stroke: var(--body-font-color) !important;
  }
}

@media (prefers-color-scheme: dark) {
  .VictoryContainer tspan {
    fill: var(--body-font-color) !important;
  }
}

.happy-file {
  width: 288px;
  height: 288px;
  background-repeat: no-repeat;
  background-size: 1500px 288px;
  background-image: url(../Images/96_happy_file.svg);
}

.filebox-ticket-details-button-container {}

.filebox-ticket-details-button-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.filebox-ticket-details-button-list .btn-group {
  width: 200px;
}

.filebox-ticket-details-button-list button {
  width: 200px;
}

.filebox-ticket-details-button-list li:not(:last-child) {
  margin-bottom: 10px;
}

.btn-split-caret {
  width: 15% !important;
}

.btn-split-main {
  width: 85%;
}

.filebox-ticket-details-header {
  display: inline-block;
}

.filebox-ticket-details-status {
  float: right;
}

.filebox-ticket-details-header-container {
  border: none !important;
  padding: 5px 20px !important;
}

.filebox-ticket-details-header-container h2 {
  border: none !important;
}

.nav-tab-content {
  margin-top: 0;
  border-top: 2px solid #006199;
}


.bs-callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #ccc;
  border-left-width: 5px;
  border-radius: 3px;
  background: #fff;
}

.instruction-icon-container span {
  padding: 5px;
}

.edit-file-transfer-container {
  margin-top: 30px;
}

.new-request-requirement-table-row td {
  vertical-align: top !important;
}

.filebox-collapse {
  transition: max-height 1s ease-out;
  overflow: hidden;
  max-height: 0;
}

.filebox-collapse.show {
  max-height: 100px;
}

.filedragLabel {
  color: #c6c6c6;
  font-size: 20px;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 20px;
  left: 0;
  width: 100%;
}

.filedrag {
  min-height: 150px;
  font-weight: 400;
  text-align: center;
  margin: 0;
  background-color: #FAFAFA;
  border: 1px dashed #CCCCCC;
  border-radius: 7px;
  cursor: default;
  position: relative;
}

.fileSelectButton {
  cursor: pointer;
  text-align: center;
  float: left;
  margin: 20px;
  line-height: 32px;
}

.bs-callout-success {
  border-left-color: #4BB543 !important;
}

.bs-callout-info {
  border-left-color: #0077BC !important;
}

.bs-callout-alert {
  border-left-color: #f0ad4e !important;
}

.bs-callout-danger {
  border-left-color: #d9534f !important;
}

.bs-callout-success :is(h4, span) {
  color: #4BB543;
}

.bs-callout-info :is(h4, span) {
  color: #0077BC;
}

.bs-callout-alert :is(h4, span) {
  color: #f0ad4e;
}

.bs-callout-danger :is(h4, span) {
  color: #d9534f;
}

.sent-from-us {
  display: none;
}

.vismaicon:hover .sent-from-us {
  padding: 5px 10px;
  border-width: 1px;
  border-style: solid;
  display: flex;
  border-color: #f6dba6 #fddfa7 #f1c575;
  background-color: #fffaf2;
  border-radius: 8px;
  -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08);
  position: absolute;
  display: block;
  visibility: visible;
  width: max-content;
  z-index: 999999;
}

.filebox-ticket-instruction-wrapper {
  width: 100%;
  display: inline-block;
}

.filebox-ticket-instruction-wrapper table {
  width: fit-content;
}

.filebox-ticket-instruction-wrapper legend {
  width: fit-content;
  border: none;
}

.filebox-ticket-instruction-wrapper fieldset {
  width: fit-content;

}

.filebox-text-container-wrapper .col-xs-12 {
  padding: 0 !important;
}

.filebox-text-container-wrapper h3 {
  border-bottom: none !important;
  margin-bottom: 5px;
  display: inline-block;
  width: 100%;
}


.filebox-text-container-wrapper h3:not(:first-child) {
  margin-top: 15px;
}

.save-filebox-text-btn-group {
  float: right;
  margin-top: 10px;
  margin-bottom: 20px;
}

.discount-search-panel {
  background-color: var(--root-background-color);
  border-radius: 0.8rem;
}

.discount-type-icon {
  top: 40px;
  left: 10px;
  position: absolute;
}

.service-detail[open]>summary>div {
  text-shadow: .25px 0px .1px;
  transition: text-shadow 0.3s;
}

.service-detail>summary>div {
  text-shadow: unset;
  transition: text-shadow 0.3s;
}

.service-label {
  text-shadow: .25px 0px .1px;
}

.text-input-error-tooltip {
  left: 3.1rem;
  top: -0.9rem;
  white-space: nowrap;
}

  .dropdown-menu>.dropdown>.dropdown-menu {
    position: static !important;
  }

  .menudrop>.dropdown-menu>.menudrop {
    height: unset !important;
  }

  .navbar-nav.first-level>.dropdown>.dropdown-menu li {
    padding-left: 1.4rem !important;
    padding: 0 1.6rem !important;
  }

  .dropdown-menu>.dropdown>.dropdown-menu li a,
  .menudrop>.dropdown-menu li a {
    padding: 0.8rem 1.6rem !important;
    font-size: 1.4rem !important;
    font-family: "Open Sans", "OpenSansFallback", sans-serif !important;
    line-height: 1.4285714 !important;
    border-radius: 0.8rem !important;
  }
  .dropdown-menu>.dropdown.open>a .caret {
    transform: rotate(180deg);
  }

  .navbar-brand>a {
    min-width: unset !important;
  }

  .navbar-left > li > a > .badge {
    width: 3.2rem !important;
    height: 2rem !important;
    top: -1.9rem !important;
    left: -1.6rem !important;
    margin: 0 !important;
    right: 0 !important
  }

  .navbar-left > li > a:has(span) {
    padding: 2rem 0rem 2rem 1.6rem !important;
  }
  
  .navbar-right {
    margin-left: auto !important;
  }
  .navbar-right > .dropdown > .dropdown-menu > li a > .vismaicon {
    vertical-align: middle;

    &::before {
      background-color: var(--icon-bg) !important;
    }
  }

  .discount-comment-modal  .form-group{
    border:none !important;
    margin-top: 3.4rem;
  }

  .skynet-icon-search.open {
    min-width: 30rem;
  }

  .skynet-topnav-search:has(.skynet-icon-search.open) {
    li:not(.skynet-icon-search) {
      opacity: 0.3;
    }

    .navbar-left {
      width: 0 !important;
    }
  }

.navbar-nav .skynet-icon-search .rbt-input-main.rbt-input {
  border-color: #48237D !important;
  background-color:#48237D !important;

  &:hover {
    border-color: white !important;
  }
}

.customer-purchase-history-container{
  flex-grow: 1;
  border: 1px solid var(--neutral-30) !important;
}

.skynet-w-35 {
  min-width: 35rem !important;
}

.skynet-table-no-divider td, .skynet-table-no-divider th, .skynet-table-no-divider tr, .skynet-table-no-divider tbody { background-image: none !important; }

.skynet-customer-logo {
  height: 5.4rem;
  margin-top: 0.7rem;
  margin-right: 0.8rem;
  width: auto;
}

.skynet-border-bottom {
  border-bottom: 1px solid var(--neutral-30) !important;
}

.skynet-customer-shortcuts-container {
  margin-left: -3.2rem !important;
  margin-right: -3.2rem !important;
  border-top: 1px solid var(--neutral-30) !important;

  > ul > li:first-child {
    margin-left: 1.6rem !important;
  }
}

.skynet-product-card-header {
  background-color: #8049CC !important;
  color: white;
}

.skynet-left-border {
  border-left: 3px solid var(--neutral-30) !important;
}

.skynet-top-border {
  border-top: 2px solid var(--neutral-30) !important;
}

.skynet-expandable-table-bg {
  background-color: var(--table-active-bg) !important;
}

.skynet-caret-toggle.toggle .caret {
  transform: rotate(-180deg);
}

.skynet-font-italic {
  font-style: italic;
}

.skynet-no-pointer-events {
  pointer-events: none;
}

.pt-1 {
  padding-top: 1px;
}