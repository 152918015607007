.customerFormEdit .btn-primary {
    margin-left: 10px;
}

.vismaicon-alignCenter {
    top: 6px;
}

.customerFunctions > div {
    display: flex
}

.customerFunctions .btn {
    min-width: 170px;
}

.customerFunctions .btn + strong {
    margin-left: 10px;
    margin-top: -2px;
}

.valignTop {
    vertical-align: top !important
}

.form-horizontal textarea {
    min-height: 100px;
}

.table-appointments .bold {
    font-weight: bold
}

.table-appointments .red {
    color: #F00
}

.customerForm .sidenav .inner-scroll .nav>li>a {
    border-bottom: 1px solid #d9d9d9;
}
.customerForm .sidenav .inner-scroll .nav.nav-one-level li:first-child>a {
    box-shadow: none;
}
.customerForm .sidenav .inner-scroll .nav.nav-one-level li:last-child>a {
    border-top: 0;
    border-bottom: 0;
    box-shadow: none;
}

.table-invoices .btn {
    min-width: 70px;
}

.table-invoices .btn .vismaicon-sm {
    top: -1px;
}

.table-invoices .pastActionInfo .vismaicon-sm {
    top: 2px;
    left: 5px;
}

.table-saas-products .pastActionInfo .vismaicon-sm {
    left: 5px;
}

.modal .modal-body .action-buttons {
    padding: 11px 20px;
    border-top: 1px solid #e5e5e5;
    text-align: right;
    margin-left: -20px !important;
    margin-right: -20px !important;
}

.customer-details .inner-container {
    padding-top: 15px;
}

.customer-details .inner-container h4 {
    border-bottom: 1px solid #dcdcdc;
}

.customerConnections {
    padding-bottom: 20px;
}

.customerConnections > * {
    padding-left: 20px;
}

.customerConnections h4 {
    border-bottom: 1px solid #dcdcdc;
    padding-left: 0px !important;
}

.customerConnections.subCustomers > div:not(:last-child) {
    padding-bottom: 10px
}

.updateDialog {
    width: 800px; 
}

.customer-details .product-suggestions .btn {
    cursor: default;
    margin-right: 10px;
}

.table .subLicenses:hover td {
    cursor: default !important
}

.customer-details .vbProducts h3 strong {
    font-size: 14px;
    float: right;
    margin-top: -8px;
}

.product-status .btn {
    cursor: default;
    margin-right: 10px;
}