.modal-body .ql-toolbar.ql-snow {
    padding: 8px 2px
}

.modal-body .ql-toolbar.ql-snow .ql-formats {
    margin-right: 10px
}

.modal-body {
    height: unset;
    overflow-y: unset;
    margin-bottom: unset;
}

.modal-dialog {
    margin: auto;
    max-height: fit-content;
}