.panel{
    margin-right: 20px;
}

.leads-panel .btn-group  {
    padding-bottom: 5px;
}

.row {
    margin-right: 0; 
    margin-left: 0;
    margin-left: 0;
    margin-right: 0;
}

.lead-panel {
    margin-left: 20px;
}

.highlightedTableRow {
    background-color: #ccc;
}

.verticalScrollTable {
    overflow-y: auto;
    max-height: 504px;
}


.leadRow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox    ;
    display: flex;
  }
  .leadRow > [class*='col-'] {
       display: flex;
       flex-direction: column;
  }