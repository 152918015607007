.calendar th {
    text-align: center;
}

.calendar td {
    text-align: center;
}

.weekDay{
    font-size: 14pt;
}