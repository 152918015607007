.card {

    background-color: var(--module-container-bg-color);
  }

  .card-container{
    flex: 1 1;
    gap: 32px 32px;
    display: flex;
    flex-direction: column;
  }
  
  .card-heading, .skynet-card-footer {
    padding: 16px 24px 0 24px;
    border-bottom: 1px solid var(--neutral-30) !important; 
    display: flex;
  }

  .card-heading {
    border-bottom: 1px solid var(--neutral-30) !important; 

    .card-btn-group {
      margin-top: -6px !important;
    }
  }

  .skynet-card-footer {
    border-top: 1px solid var(--neutral-30) !important; 

    .card-btn-group {
      padding-bottom: 8px !important;
    }
  }

  .card-title {
    font-weight: 400;
    font-size: 20px;
  }
  
  .card-row-horizontal {
    display: flex;
  }
  
  .card-column {
    flex: 1 0 auto;
    flex-basis: 0;
  }
  
  .card-row-vertical {
    display: flex;
    flex-direction: column;
  }
  
  .card-row-item {
    flex: 1;
  }

  .card-column > .card-row{
    height: 100%;
  }

  .skynet-card-logo {
    width: 70px;
    height: 70px;
    position: relative;
    left: -8px;
  }

  .skynet-card-logo > img {
    border: none !important;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .card-btn-group {
    margin-left: auto !important;
  }

  .card-heading.collapse .caret {
    transform: rotate(-180deg);
  }

  .card:has(.collapsible) {
    max-height: 100% !important;
    overflow: hidden !important;
    -webkit-transition: max-height ease-in-out;
    -moz-transition: max-height ease-in-out;
    -o-transition: max-height ease-in-out;
    transition: max-height ease-in-out;
  }

  .card:has(.collapsible.collapse) {
    max-height: 56px !important;
    overflow: hidden !important;
    -webkit-transition: max-height ease-in-out;
    -moz-transition: max-height ease-in-out;
    -o-transition: max-height ease-in-out;
    transition: max-height ease-in-out;
  }
  
  .skynet-card-table {

    tr:nth-child(odd) {
      background-color: brown !important;
    }

    tr:nth-child(even) {
      background-color: aquamarine !important;
    }
    
    td {
      padding: 0 0 0 24px !important;
    }
  }

.skynet-sub-border.active {
  box-shadow: inset 0 -2px  var(--green-60) !important;
}