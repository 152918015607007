.alertWrapper {
    position: fixed;
    top: 42px;
    width: 100%;
    z-index: 9999;
    pointer-events: none;
}

.alertWrapper .alert {
    width: 50%;
    text-align: center;
    margin: 0 auto;
    pointer-events: all;
}

@media (max-width: 768px) {
    .alertWrapper .alert {
        width: 80%;
    }

}
