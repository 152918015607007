.skynet-input-icon {
    position: absolute !important;
    top: 2.6rem !important;
    right: 1.0rem !important;
}

.skynet-input:read-only {
    cursor: pointer !important;
}

.skynet-input {
    border-radius: 0.8rem !important;
    border: none !important;
    background-color: var(--table-alt-bg) !important;
    padding-left: 1.6rem !important;
  }
  
.skynet-table-input {
    background-color: transparent !important;
    border: dotted 1px !important;
    padding-left: 0 !important;
    width: 100% !important;
}

.skynet-form-input {
    position: relative !important;
}

div.skynet-form-input {
    margin-bottom: 0 !important;
}

.skynet-input-divider {
    border-right: .2rem solid var(--neutral-30) !important;
}

.form-control.required>label:before, .form-control.required>a:before {
    position: absolute;
    content: "*";
    margin-left: -0.7rem;
    color: #CC334C;
    color: var(--input-error-text-color);
    font-size: 1.3rem;
}