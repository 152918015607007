.ql-container {
    min-height: 100px;
    max-height: 200px;
    overflow-y: auto;
    padding-left: 12px !important;
}

.ql-disabled {
    cursor: default!important;
    color: #a0a0a0;
}